import { Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import GameRouterComponent from "GameRouterComponent";
import SignalRComponent from "SignalRComponent";

const RouterComponent = () => {
  const [selectedValue, setSelectedValue] = useState("game");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Select functionality</FormLabel>
        <Divider orientation="vertical" variant="middle" flexItem />
        <RadioGroup row onChange={handleChange} aria-labelledby="demo-radio-buttons-group-label" defaultValue={selectedValue} name="row-radio-buttons-group">
          <FormControlLabel value="game" control={<Radio />} label="Test game" />
          <FormControlLabel value="connection" control={<Radio />} label="Test connection" />
        </RadioGroup>
      </FormControl>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Divider orientation="horizontal" flexItem />
      <Divider orientation="vertical" variant="middle" flexItem />
      {selectedValue === "game" && <GameRouterComponent />}
      <br />
      {selectedValue === "connection" && <SignalRComponent />}
    </div>
  );
};

export default RouterComponent;
