import { Button, Grid, TextField } from "@mui/material";
import useGame from "useGame";

const GameComponent = () => {
  const { handleName, handleBalance, handleStart, startButtonDisabled, context } = useGame();

  return (
    <div>
      {context.start === false && (
        <Grid item xs={4}>
          <Grid container xs={12} gap={2}>
            <Grid xs={12}>
              <TextField type="text" onChange={(e) => handleName(e.target.value)} id="outlined-disabled" label="Name" />
            </Grid>
            <Grid xs={12}>
              <TextField type="number" onChange={(e) => handleBalance(e.target.value)} id="outlined-disabled" label="Balance" />
            </Grid>
            <Grid xs={12}>
              <Button disabled={startButtonDisabled} onClick={handleStart} variant="contained">
                Start game
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default GameComponent;
