import { GameRound } from "interfaces";
import { useEffect, useState } from "react";
import signalRService from "signalRService";
import { useAppContext } from "useAppContext";
import { isFalsy } from "utility-types";

const useGameRound = () => {
  const context = useAppContext();
  const [messages, setMessages] = useState<string[]>([]);
  const [multiplier, setMultiplier] = useState(0);
  const [gameRound, setGameRound] = useState<GameRound>({} as GameRound);
  const [history, setHistory] = useState<GameRound[]>([]);
  const [stake, setStake] = useState<number>(0);
  const [cashout, setCashout] = useState<boolean>(false);
  const [bet, setBet] = useState<boolean>(false);
  const [buttonBetDisabled, setButtonBetDisabled] = useState<boolean>(true);
  const [buttonCashoutDisabled, setButtonCashoutDisabled] = useState<boolean>(true);
  const [stakeDisabled, setStakeDisabled] = useState<boolean>(true);
  const [win, setWin] = useState<number>(0);
  const [start, setStart] = useState<boolean>(false);

  useEffect(() => {
    signalRService.onReceiveStart((receivedJson: string) => {
      console.log("Message receive", receivedJson);
      setButtonBetDisabled(true);
      setButtonCashoutDisabled(isFalsy(bet));
      setStakeDisabled(true);
      setStart(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveEnd((receivedJson: string) => {
      console.log("Message receive", receivedJson);
      setButtonCashoutDisabled(true);
      setStakeDisabled(false);
      setStake(0);
      setCashout(false);
      setWin(0);
      setStart(false);
    });
  });

  useEffect(() => {
    signalRService.onReceiveGameRound((receivedJson: string) => {
      console.log("Message receive", receivedJson);

      let object: GameRound = JSON.parse(receivedJson);

      setMultiplier(object.multiplier);

      if (gameRound.gameRoundId !== object.gameRoundId) {
        setGameRound(object);
      }

      if (object.active === false) {
        setHistory([...history, object]);
        setMessages([]);
      } else {
        setMessages([...messages, receivedJson]);
      }

      console.log("Messages array", messages);
    });
  }, [gameRound, history, messages]);

  useEffect(() => {
    signalRService.onReceiveGameRound((receivedJson: string) => {
      console.log("Message receive", receivedJson);

      let object: GameRound = JSON.parse(receivedJson);

      setMultiplier(object.multiplier);

      if (gameRound.gameRoundId !== object.gameRoundId) {
        setGameRound(object);
      }

      if (object.active === false) {
        setHistory([...history, object]);
        setMessages([]);
      } else {
        setMessages([...messages, receivedJson]);
      }

      console.log("Messages array", messages);
    });
  }, [gameRound, history, messages]);

  const clickButtonBet = () => {
    let payload = "";

    setBet(true);
    setButtonBetDisabled(true);
    setStakeDisabled(true);
    payload = JSON.stringify({ stake: stake, player: context.name, clientTime: Date.now() });
    console.log("BET", payload);

    signalRService.sendBet(payload);

    context.setBalance(context.balance - stake);
  };

  const clickButtonCashout = () => {
    let payload = "";

    payload = JSON.stringify({ player: context.name, clientTime: Date.now() });
    console.log("CASHOUT", payload);

    signalRService.sendCashout(payload);

    setCashout(true);
    let win = multiplier * stake;
    setWin(win);
    context.setBalance(context.balance + win);
    setButtonCashoutDisabled(true);
  };

  const changeStake = (data: string) => {
    let stake = Number(data);

    setStake(stake);

    if (stake > 0) {
      setButtonBetDisabled(false);
    }

    if (stake > context.balance) {
      setButtonBetDisabled(true);
    } else {
      setButtonBetDisabled(false);
    }
  };

  return { start, win, cashout, bet, stake, messages, multiplier, history, buttonBetDisabled, buttonCashoutDisabled, stakeDisabled, clickButtonBet, clickButtonCashout, changeStake, context };
};

export default useGameRound;
