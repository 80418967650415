import { useState } from "react";
import { useAppContext } from "useAppContext";
import { isFalsy } from "utility-types";

const useGame = () => {
  const context = useAppContext();
  const [startButtonDisabled, setStartButtonDisabled] = useState(true);

  const handleBalance = (data: string) => {
    context.setBalance(Number(data));

    handleCheck();
  };

  const handleName = (data: string) => {
    context.setName(data);

    handleCheck();
  };

  const handleCheck = () => {
    if (isFalsy(context.name) === false && isFalsy(context.balance) === false) {
      setStartButtonDisabled(false);
    }
  };

  const handleStart = () => {
    context.setStart(true);
  };

  return { handleBalance, handleName, handleStart, startButtonDisabled, context };
};

export default useGame;
