import "./App.css";
import RouterComponent from "RouterComponent";
import { AppContextProvider } from "useAppContext";
import { useState } from "react";

function App() {
  const [start, setStart] = useState(false);
  const [balance, setBalance] = useState(0);
  const [name, setName] = useState("");

  return (
    <div className="App">
      <AppContextProvider context={{ start, setStart, balance, setBalance, name, setName }}>{<RouterComponent />} </AppContextProvider>
    </div>
  );
}

export default App;
