import GameComponent from "GameComponent";
import GameRoundComponent from "GameRoundComponent";
import { useAppContext } from "useAppContext";

const GameRouterComponent = () => {
  const context = useAppContext();
  return <div>{context.start ? <GameRoundComponent /> : <GameComponent />}</div>;
};

export default GameRouterComponent;
